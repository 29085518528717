<template>
    <div class="mt-6">
        <div v-if="showHeader">
            <div class="text-right">
                <button
                    @click="handleDownload()"
                    class="px-4 py-2 mb-4 bg-gray-50 border rounded-md text-balck flex"
                >
                    <img src="../../../assets/excel.png" alt="" />
                    <span class="mx-3">Download</span>
                </button>
            </div>
            <!-- deploying -->
            <div class="flex text-lg mb-4">
                <h1 class="pr-4 border-r uppercase font-bold">
                    <!-- {{ this.data[0].branch }} -->
                </h1>
                <h1 class="px-4 border-r">
                    From:
                    <span class="font-bold">{{
                        $route.params.from | moment('ddd, MMM Do YYYY')
                    }}</span>
                </h1>
                <h1 class="px-4">
                    To:
                    <span class="font-bold">{{
                        $route.params.to | moment('ddd, MMM Do YYYY')
                    }}</span>
                </h1>
            </div>
        </div>
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        DATE
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        AGENT
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        NO. TRANSACTIONS
                    </th>

                    <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        SALES
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-3 text-xs font-medium text-right text-gray-500 uppercase tracking-wider"
                    >
                        CHAPMAN COMMISSION
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-3 text-xs font-medium text-right text-gray-500 uppercase tracking-wider"
                    >
                        AGENT COMMISSION
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-3 text-xs font-medium text-right text-gray-500 uppercase tracking-wider"
                    >
                        TOTAL COMMISSION
                    </th>
                </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(transaction, i) in data" :key="i">
                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                            <div class="ml-4">
                                <div class="text-sm font-medium text-gray-900">
                                    {{ i }}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">
                            {{ transaction.agent }}
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">
                            {{ transaction.transactions }}
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            GMD <b>{{ formatPrice(transaction.sales) }}</b>
                        </div>
                    </td>

                    <td
                        class="px-6 py-4 text-center whitespace-nowrap text-right"
                    >
                        <div class="text-sm font-bold text-gray-900">
                            GMD
                            <b>{{
                                formatPrice(transaction.chapman_commission)
                            }}</b>
                        </div>
                    </td>

                    <td
                        class="px-6 py-4 text-center whitespace-nowrap text-right"
                    >
                        <div class="text-sm font-bold text-gray-900">
                            GMD
                            <b>{{
                                formatPrice(transaction.agent_commission)
                            }}</b>
                        </div>
                    </td>

                    <td
                        class="px-6 py-4 text-center whitespace-nowrap text-right"
                    >
                        <div class="text-sm font-bold text-gray-900">
                            GMD
                            <b>{{
                                formatPrice(transaction.total_commission)
                            }}</b>
                        </div>
                    </td>
                </tr>

                <tr class="bg-gray-50">
                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                            <div class="ml-4">
                                <div class="text-sm font-medium text-gray-900">
                                    <b>TOTAL</b>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900"></div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">
                            <b>{{ totalTransactions }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            GMD <b>{{ formatPrice(totalSales) }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-right">
                        <div class="text-sm text-gray-900">
                            GMD <b>{{ formatPrice(chapmanCommission) }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-right">
                        <div class="text-sm text-gray-900">
                            GMD <b>{{ formatPrice(agentCommission) }}</b>
                        </div>
                    </td>

                    <td
                        class="px-6 py-4 text-center whitespace-nowrap text-right"
                    >
                        <div class="text-sm font-bold text-gray-900">
                            GMD
                            <b>{{ formatPrice(total) }}</b>
                        </div>
                    </td>
                </tr>

                <!-- More people... -->
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ['data', 'showHeader'],

    data: () => ({
        downloadLoading: false,

        filename: '',
        autoWidth: true,
        bookType: 'xlsx',
    }),

    computed: {
        totalTransactions: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log(key);
                total.push(val.transactions); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },

        totalSales: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log(key);
                total.push(val.sales); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },

        chapmanCommission: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log(key);
                total.push(val.chapman_commission); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },

        agentCommission: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log(key);
                total.push(val.agent_commission); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },

        total: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log(key);
                total.push(val.total_commission); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },
    },

    methods: {
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(',', '.');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },

        getFileName() {
            return (
                this.$route.params.reportOn +
                '-' +
                this.$route.params.from +
                '-' +
                this.$route.params.to
            );
        },

        handleDownload() {
            this.downloadLoading = true;
            import('@/vendor/Export2Excel').then(excel => {
                const tHeader = [
                    'Staff',
                    'Transactions',
                    'Amount',
                    'Commission',
                    'Total',
                ];
                const filterVal = [
                    'staff',
                    'transactions',
                    'amount',
                    'commission',
                    'total',
                ];
                const list = this.data;
                console.log('LIST', list);
                const data = this.formatJson(filterVal, list);
                excel.export_json_to_excel({
                    header: tHeader,
                    data,
                    filename: this.getFileName(),
                    autoWidth: this.autoWidth,
                    bookType: this.bookType,
                });
                this.downloadLoading = false;
            });
        },

        formatJson(filterVal, jsonData) {
            return Object.keys(jsonData).map(v => {
                console.log();
                return filterVal.map(j => {
                    console.log('J', j);
                    console.log();

                    if (j === 'staff') {
                        return v;
                    } else if (j === 'total') {
                        return (
                            Number(jsonData[v]['amount']) +
                            Number(jsonData[v]['commission'])
                        );
                    } else {
                        return jsonData[v][j];
                    }
                });
            });
        },
    },
};
</script>
